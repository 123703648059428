import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "./index.css";

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Menu" />
    <h1>Menu</h1>
    <section className="menu-items">
      {data.allMdxBlogPost.nodes.map(node => (
        <article key={node.id}>
          <h3 className="post-title">
            <Link to={node.permalink}> {node.title} </Link>
          </h3>
          <span className="dots" />
          <span className="post-date"> {node.date} </span>
        </article>
      ))}
    </section>
  </Layout>
);

export const query = graphql`
  query {
    allMdxBlogPost(sort: { fields: [date], order: DESC }) {
      nodes {
        id
        slug
        permalink
        title
        date(formatString: "MM/YYYY")
      }
    }
  }
`;

export default IndexPage;
